:root {
  --main-color-1: #1d3740;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

tr:nth-child(odd) {
  background-color: #f2f2f2;
}

td,
th {
  text-align: justify !important;
}

.MuiButton-outlined {
  border-width: 0 !important;
  padding: 0 !important;
  margin-right: -1rem !important;
}

.MuiButton-outlined > .MuiButton-label > .MuiSvgIcon-root {
  color: rgba(0, 0, 0, 0.45);
}

/* a[class^="MuiButtonBase-root MuiListItem-root"] {
  color: white !important;
  font-weight: bold;
}

span[class^="MuiTypography-root"] {
  color: white !important;
  font-weight: bold;
} */
/* div[class^="RaMenu"] a {
  color: white;
  font-weight: bold;
}

div[class^="RaMenu"] div {
  color: white;
}
a[class*="RaMenuItemLink"] div {
  font-weight: bold;
  color: white;
}

div[class*="MuiListItemIcon-root makeStyles-icon-47"] {
  color: white;
}

a[class*="RaMenuItemLink-active"] {
  font-weight: bold;
} */
/*

MuiButtonBase-root MuiListItem-root MuiMenuItem-root RaMenuItemLink-root-44 MuiMenuItem-gutters MuiListItem-gutters MuiListItem-button

div[class^="RaSidebar"] {
  background: var(--main-color-1);
}

div[class^="RaMenu"] a {
  color: white;
  font-weight: bold;
}

div[class^="RaMenu"] div {
  color: white;
  font-weight: bold;
}

a[class*="RaMenuItemLink-active"] {
  background: #999999;
}

*/
